import {
  Box,
  Text,
  Icon,
  Button,
} from "@shopify/polaris";
import "./styles.customorder.css";
import { CheckCircleIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import { useEffect, useState } from "react";


export default function OrderProofAcceptedBanner({setLoading, order, setOrderList, user  }) {
  const [proofFile, setProofFile] = useState([]);

  useEffect(() => { 
    if (order?.files?.length > 0) {
      setProofFile(order.files.filter((f) => f.isProofSent))
    }else setProofFile([])
  }, [order]);

  const formatter = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });



  const handleRejectFile = () => {
    setLoading(true);
    const data = {
      id: order.id,
      shop: order.shop,
      name: proofFile[proofFile.length - 1].name,
      link: proofFile[proofFile.length - 1].link,
      file: {
        "files.$.customerRejected": true,
        "files.$.customerAcceptedManually": true,
        "files.$.customerAccepted": false,
        "files.$.customerRejectedDate": new Date().toISOString(),
      },
      user: user.artist.name,
      email: user.artist.email
    }
    api.post("/orderDetailsFileUpdate", data).then((result) => {
      if(result.data.id)
        setOrderList(result.data);
      setLoading(false);
    }).catch((err) => setLoading(false)); 
  }

  const handlePrintPurshaseOrder = async () => {
    const data = {
      id: order.id,
      user: user.artist.name,
      email: user.artist.email
    }
    await api.post("/OPUsers", data).then((result) => {
      if(result.data.id)
        setOrderList(result.data);
    });
    if (order.PO_link) {
      window.open(order.PO_link, '_blank', 'noopener,noreferrer');
      return;
    } else {
      setLoading(true);
      api.get(`/htmlOPPdf?id=${order.id}`).then((result) => {
        if (result.data.id) {
          setOrderList(result.data);
          window.open(result.data.PO_link, '_blank', 'noopener,noreferrer');
        }
        setLoading(false);
      }).catch((error) => setLoading(false));
    }
     
  }

  

  return (
    <Box width="100%">
    <div className="banner banner-success">
        <span className="icon-success-wrapper"><Icon size="small" source={CheckCircleIcon}/></span>
        <div className="banner-body">
          <Text variant="headingLg" as="h3" tone="subdued" fontWeight="bold"> Proof accepted</Text>
          <div className="banner-body-list">
            {order.current_status_by === 0 ? <Text tone="subdued" variant="headingMd" as="p" fontWeight="regular" >{`You manually approve this order ${order?.current_status_date && " on " + formatter.format(new Date(order?.current_status_date))}`}</Text> :
              <Text tone="subdued"  variant="headingMd" as="p" fontWeight="regular" > {`${order.customer.last_name && order.customer.first_name ? `${order.customer.first_name} ${order.customer.last_name}` : order.customer.first_name ? `${order.customer.first_name}` : order.customer.last_name ? `${order.customer.last_name}` : ""} has accepted your proof ${order?.current_status_date && " on " + formatter.format(new Date(order?.current_status_date))}`}</Text>}
            {(order.PO_date && order.PO_users?.length)  && <Text tone="subdued"  variant="headingMd" as="p" fontWeight="regular" > {`Purchase order printed on ${formatter.format(new Date(order.PO_users.length ? order.PO_users[order.PO_users.length-1].date:order.PO_date))} by ${order.PO_users.length ? order.PO_users[order.PO_users.length-1].user : "admin"}`}</Text>}
          </div>
            <div className="banner-action">
            <div onClick={handleRejectFile} className="btn reject-btn">Reject Manually</div>
        </div>
        </div>
        
    </div>  
    </Box>         
  );
}
            
