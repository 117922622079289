import {
  Box,
  Text,
  Collapsible,
  Button,
  TextField,
  Avatar,
  Icon,
} from "@shopify/polaris";
import { useState, useEffect } from 'react';
import "./styles.customorder.css";
import { SendIcon, UploadIcon} from "@shopify/polaris-icons";
import "./styles.customorder.css";
import api from "../axiosClient";
import { MentionsInput, Mention } from "react-mentions";

export default function OrderComments({ id, shop, orderComments, setOrderList, user }) {
  const [options, setOptions] = useState(false);
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([...orderComments]);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // Handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Store base64 image
        setImagePreview(reader.result); // For preview
      };
      reader.readAsDataURL(file);
    }
  };

  function formatDate(dateString) {
    const options = {
      day: '2-digit',
      month: 'long',    
    };
    
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }

  function formatTime(dateString) {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }

  console.log(user)
 
  useEffect(() => {
    handleGetSettings()
    console.log("OrderComments",orderComments)
    setCommentList([...orderComments])
  }, []);
  useEffect(() => {
    
    
    setCommentList([...orderComments])
  }, [orderComments]);

  const handleAddComment = () => {
    if (!comment.trim() && !image) return; // Prevent empty submission
  
    setComment("");
    setCommentList([
      ...commentList,
      {
        text: comment,
        date: new Date(),
        name: user.artist.name,
        image: image ? image.base64 : null, // Store base64 if exists
      },
    ]);
  
    const getIdFromComment = (comment, data) => {
      for (let entry of data) {
        if (comment.includes(entry.display)) {
          return entry.id;
        }
      }
      return null;
    };
  
    const extractedId = getIdFromComment(comment, options);
  
    // Prepare request payload
    const requestData = {
      id,
      shop,
      comment,
      name: user.artist.name,
      email: extractedId,
      file: image || null, // Attach base64 file if exists
    };
  
    // Send request
    handleUpdateOrder(requestData);
  
    setImage(null);
  };
  
  const handleUpdateOrder = (data) => {
    api.post("/updateOrderComments", data).then((result) => {
      setOrderList(result.data);
    });
  };
  

  function getInitials(name) {
    // Split the name into an array of words
    const words = name.split(' ');
  
    // Get the first letter of each of the first two words
    const initials = words.slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');
  
    return initials;
  }

  function formatDateToLocal(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA'); // 'en-CA' gives 'YYYY-MM-DD' format
}
  
  const groupedComments = commentList.reduce((acc, comment) => {
    const localDate = formatDateToLocal(comment.date);
    if (!acc[localDate]) {
        acc[localDate] = [];
    }
    acc[localDate].push(comment);
    return acc
  }, {});
  
  const handleGetSettings = () => {
    api.get(`/getSettingsByStore?shop=${shop}`)
  .then((response) => {
    const artists = response.data.artist;
    
    const options = artists.map((artist) => ({
      display: artist.name, 
      id: artist.email
    }));
    setOptions(options);
    
  })
  .catch((error) => {
    console.error("Error fetching settings:", error);
  });
  }

  const getFileExtension = (link, type) => {
    // Extract the expected extension from the MIME type
    const expectedExtension = '.' + type?.split('/')[1];

    // If the URL already contains a file extension at the end, return an empty string
    if (/\.[a-zA-Z0-9]+$/.test(link)) {
        return '';
    }

    // Otherwise, append the correct extension
    return expectedExtension;
};

  return (
    <>
    <Text as="h6" tone="subdued" fontWeight="semibold" >
              Comments
              </Text> 
      <Box id="comment-box" >
        <div className="comment-column-footer">
          <Avatar size="xl" initials={getInitials(user.artist.name)} name="Woluwayemisi Weun-Jung" />
          <div style={{width:"100%"}}>
          {/* <TextField
                placeholder="Leave a comment..."
                value={comment}
                onChange={setComment}
                multiline={1}
              autoComplete="off"
              id="text-box"
            /> */}
            <MentionsInput
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Leave a comment..."
        className="text-box"
        style={{
          control: { fontSize: 14 },
          highlighter: { overflow: 'hidden' },
          input: { marginBottom: 0, }
          
        }}
      >
        <Mention
          trigger="@"
          data={options}
          style={{ backgroundColor: "#d1c4e9" }}
        />
            </MentionsInput>
            {/* Image Upload Section */}
          <div style={{ marginTop: "10px",display:"flex" }}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="file-input"
            />
            <label htmlFor="file-input" className="btn">
              Attach Image
            </label>
          </div>

          
          </div>
        </div>
        
      </Box>  
      <Box id="comment-post" >
      <div style={{float:"right", marginTop:"5px"}}><div onClick={handleAddComment}  style={{padding:"4px 10px", minWidth:"60px"}} className="btn">Post</div></div>
      </Box>

      <ul class="StepProgress-Comment">
        {Object.values(groupedComments).reverse().map((com, index) =>
          <>
            <div style={{ marginTop: index > 0 ? "10px" : "70px", paddingLeft: "10px" }} class="StepProgress-item-status">{formatDate(com[0].date)}</div>
         {com.slice() // Create a copy to avoid mutating the original array
  .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by latest date first
  .map((com) => <li  class={`StepProgress-Comment-item is-done`}>
              <div className="StepProgress-item-container">
                <div className="StepProgress-item-comment">{com.link && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}{com.text}{<div style={{display:"flex"}}>{com.name?`(${com.name})`:""}{com.link && <a target="_blank"
              rel="noopener noreferrer" style={{ transform: "scaleY(-1)", height: "fit-content", color: "#000000" }} href={`${com.link}`}> <span ><Icon tone="inherit" source={UploadIcon} /></span></a>}</div>}</div>
                <div className="StepProgress-item-time">{formatTime(com.date)}</div>
              </div>
         </li>)}
         </>
        )}
{/*         
          <li style={{marginTop: "0px"}}class={`StepProgress-Comment-item is-done`}> 
          <div class="StepProgress-item-status">{"order.status"}`</div>
          <div className="StepProgress-item-comment">`{"order.comment"}`</div>
          <div className="StepProgress-item-time">`{"formatDate(order.date)"}`</div>
        </li> */}
        
          </ul>
            {/* <div className="right-column-body bottom-padding-remove">
            
            <div class="scrollable-view">

          { commentList.map((com) => <><div id="comment-1">
                <div style={{display:"flex", justifyContent:"space-between", alignItems: "center", gap:"5px", marginTop:"5px"}}>
                    <Text as="h4" tone="subdued" fontWeight="bold" >
                    {com.name}
                </Text> 
                <Text as="span" tone="subdued"  >
                    {formatDate(com.date)}
                  </Text> 
                  </div>
              </div>
              <Text as="span" tone="subdued"  >
                    {com.text}
          </Text></>)}
          
              </div>
              
            </div> */}
            
      
       
      </>
  );
}
            
